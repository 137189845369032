


      <div class="modal-header">

        <button type="button" class="close" aria-label="Close" (click)="activeModal.close(true)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="icon">
            <img src="../../../assets/images/Antu_dialog-error.svg.png" alt="" height="80">
          </div>
         <div class="text1">
          Desculpe! O portal não conseguiu realizar esta requisição.
        </div>
         <div class="text2">
         Tente novamente mais tarde ou entre em contato com o administrador.
         </div>
         <div class="text3">
          {{confirmationMessage}}
         </div>
        </div>
        <div class="modal-footer">
           <button  class="button search-button" (click)="activeModal.close(true)" >VOLTAR</button>
        </div>

      </div>

