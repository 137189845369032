<div class="login">

  <div class="header">
    <h1>PLATAFORMA DE SERVIÇOS DE COBRANÇA</h1>
  </div>
  <div class="login-box">
    <h2>Login</h2>
    <p>Insira o nome do usuárilo e senha para entrar!</p>

    <form #f="ngForm"(ngSubmit)="onSubmit(f)" class="form">
      <div>
        <label for="user">Usuário</label>
        <input type="email"
          name="user"
          id="user"
          placeholder="Insira aqui seu e-mail"
          required
          ngModel
          #user>
      </div>
      <div>
        <label for="password">Senha</label>
        <input type="password"
          name="password"
          id="password"
          placeholder="Insira aqui sua senha"
          required
          ngModel>
      </div>

      <div *ngIf="loginError" class="login-error">
       <p> Erro no login </p>
      </div>

      <button type="submit" class="button search-button"
        *ngIf="!showLoader else loading"
      >
        Logar</button>

      <ng-template #loading>
        <div class="loader"></div>
      </ng-template>

    </form>

    <p>Esqueceu sua senha? <a href="#forget-password">Clique aqui. </a></p>
  </div>
  <div class="footer">
    <img src="../../assets/images/logo-novo.svg" alt="logo">
  </div>

</div>
