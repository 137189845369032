import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;
/**
 * Component for full layouts
 */
@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  // styleUrls: ["./full.component.scss"]
})
export class FullComponent implements OnInit {

  constructor(public router: Router) {}

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showMobileMenu = false;
  public expandLogo = true;
  public year: any;
  public footer: string;

  options = {
    sidebartype: "mini-sidebar",
  };

  Logo() {
  //  this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === "/") {
      this.router.navigate(["/home"]);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.year = new Date().getFullYear();
    this.footer = `© ${this.year} PRAVALER S/A – Todos os direitos reservados`;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
        if (this.innerWidth < 1170) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;
      default:
    }
  }
}
