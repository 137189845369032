import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "../services/auth";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    IsZend: boolean;

    constructor(private authenticationService: AuthenticationService) { 
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userValue;
        const isLoggedIn = user && user.jwtToken;
        const isApiUrl = request.url.startsWith(environment.KRAKEND);
        
        // const isApiUrlIntegration = request.url.startsWith(environment.INTEGRATION);

        if (isLoggedIn && (isApiUrl )) {
            // console.log(user);

            request = request.clone({
                setHeaders: { Authorization: `${user.jwtToken}` }
            });
        }

        return next.handle(request);
    }
}
