import * as $ from "jquery";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
	CommonModule,
} from "@angular/common";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient,HTTP_INTERCEPTORS } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FullComponent } from "./layouts/full/full.component";

import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";

import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./shared/spinner.component";
import { ChartsModule } from "ng2-charts";
import { ModalErrosComponent } from "./layouts/modal-erros/modal-erros.component";
import { ModalNoResultComponent } from "./layouts/modal-no-result/modal-no-result.component";
import { AuthGuard } from "./helpers/auth.guard";
import { ScheduleGuard } from "./helpers/schedule.guard";
import { AuthenticationService } from "./services/auth";
import { ReceiveTokenComponent } from "./receive-token/receive-token.component";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { Page404Component } from "./layouts/page404/page404.component";
import { LoginComponent } from './login/login.component';

@NgModule({
	declarations: [
		AppComponent,
		SpinnerComponent,
		FullComponent,
		NavigationComponent,
		BreadcrumbComponent,
		SidebarComponent,
		ModalErrosComponent,
		ModalNoResultComponent,
		ReceiveTokenComponent,
		Page404Component,
		LoginComponent
  ],
  providers:[
    HttpClientModule,
    AuthGuard,
    ScheduleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
   ],
	imports: [
		CommonModule,
		ChartsModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		RouterModule.forRoot(Approutes, { useHash: false }),
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
