import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../app/services/auth/authentication.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showLoader:boolean = false;
  loginError: Boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
  }



  onSubmit(form) {
    this.showLoader = true;
    this.authenticationService
    .login(
      form.form.value.user,
      form.form.value.password
      )
    .subscribe( (data) => {
      this.getNegotiorToken(data[0], data[1]);
    },
    (error) => {
      // console.log(error);
      this.loginError = true;
      this.showLoader = false;
    });
  }

  getNegotiorToken(keyMakerToken, username) {
    this.authenticationService.getToken(keyMakerToken).subscribe(
      (negotiorToken) => {
        this.showLoader = false;
        this.authenticationService.setUser(keyMakerToken, negotiorToken, username);
        this.router.navigate(["/home"]);
      },
      (error) => {
        // console.log(error);
        this.loginError = true;
        this.showLoader = false;
    });
  }
}
