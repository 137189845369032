import { Component, AfterViewInit, OnInit } from "@angular/core";
import { ROUTES } from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/services/auth";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  public sidebarnavItems: any[];
  roleSchedule: boolean = this.Authentication.hasRole("api-cobranca:scheduler:agendar.comunicacao");

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private Authentication: AuthenticationService,
  ) {}

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter( (sidebarnavItem) => sidebarnavItem);
    this.sidebarnavItems = this.verifyScheduleRole(this.sidebarnavItems);
  }

  verifyScheduleRole(sidebarItems) {
    if (!this.roleSchedule) {
      sidebarItems = sidebarItems.filter((value) => {
        return value.role != "schedule";
      });
    }
    return sidebarItems;
  }

  redirect(deal, active) {
    if(active) {
      this.router.navigate(
        ["/deal/search-by-cpf", {
          deal
        }]
      );
    }
  }


}
