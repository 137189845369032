import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ValidaTokenServiceService {

  constructor(
    private Authentication: AuthenticationService,
  ) { }

  validaToken() {
    let user = JSON. parse(localStorage.getItem('User'));
    if (user){
      let krakenIdToken = user.krakenId;
      let decodedToken = this.Authentication.decodePayloadJWT(krakenIdToken);
      return !this.Authentication.tokenExpired(decodedToken);
    }
    return false;
  }
}
