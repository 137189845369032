import { AuthenticationService } from "./../services/auth/authentication.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-receive-token",
  templateUrl: "./receive-token.component.html",
  // styleUrls: ["./receive-token.component.css"]
})
export class ReceiveTokenComponent implements OnInit {
token: any;
tokenDecode: any;

  constructor(
    private activateRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.token = this.activateRoute.snapshot.paramMap.get("tk");
    this.tokenDecode = this.authService.decodePayloadJWT(this.token);
    this.authenticationToken();
  }

  

  private authenticationToken(){
    if(!this.authService.tokenExpired(this.tokenDecode)){
      this.authService.validateToken(this.token).subscribe();
    }else{
      this.router.navigate(["/home"]);
    }
  }

  // private validaToken(token){
  //   this.token = token;
  //   // this.authService.decodePayloadJWT();
  //   // this.tokenExpired(this.authService.decodePayloadJWT());
  // }

}
