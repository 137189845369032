import { AuthenticationService } from "../services/auth/authentication.service";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class ScheduleGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  canActivate() {
    if (this.authenticationService.hasRole("api-cobranca:scheduler:agendar.comunicacao")) {
      return true;
    }
    this.router.navigate(["/home"]);
    return false;
  }
}
