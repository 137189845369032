<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- First level menu -->
    <li
      class="sidebar-item"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        <i [ngClass]="[sidebarnavItem.icon]"></i
        ><span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </div>
      <a
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="
          (sidebarnavItem.class === '' && sidebarnavItem.redirect === false) ? [sidebarnavItem.path] : null
        "
        [ngClass]="[sidebarnavItem.class]"
        [class.active]="showMenu === sidebarnavItem.title"
        *ngIf="!sidebarnavItem.extralink"
        (click)="addExpandClass(sidebarnavItem.title); redirect(sidebarnavItem.path, sidebarnavItem.redirect);"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </a>
      <ul
        class="submenu"
        [class.active]="showMenu === sidebarnavItem.title"
      >
        <li
          class="sidebar-item"
          *ngFor="let submenuItem of sidebarnavItem.submenu"
        >
          <div class="nav-small-cap" *ngIf="submenuItem.extralink === true">
            <i [ngClass]="[submenuItem.icon]"></i
            ><span class="hide-menu">{{ submenuItem.title }}</span>
          </div>
          <a
            class="sidebar-link waves-effect waves-dark"
            [routerLink]="(submenuItem.class === '' && submenuItem.redirect === false) ? [submenuItem.path] : null"
            [ngClass]="[submenuItem.class]"
            (click)="redirect(submenuItem.path, submenuItem.redirect)"
            *ngIf="!submenuItem.extralink"
          >
            <i [ngClass]="[submenuItem.icon]"></i>
            <span class="hide-menu">{{ submenuItem.title }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
