import { AuthenticationService } from "./../services/auth/authentication.service";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ValidaTokenServiceService } from "../services/auth/valida-token-service.service";



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private validaToken: ValidaTokenServiceService
  ) {
  }

  canActivate() {
    const user = this.authenticationService.userValue;
    if (user || this.validaToken.validaToken()) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
