import { Component, AfterViewInit } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/services/auth";
declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"]

})
export class NavigationComponent implements AfterViewInit {

  public showSearch = true;
  public username = "Cobrança";

  constructor(
    private modalService: NgbModal,
    private Authentication: AuthenticationService
    ) { }

  ngOnInit() {
    let user = JSON. parse(localStorage.getItem('User'));
    if (user) {
      this.username = user.username;
    }
  }

  ngAfterViewInit() {

  }

  logout(){
    this.Authentication.logout();
  }
}
