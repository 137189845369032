import { Component, OnInit, Input } from "@angular/core";
import {NgbModal, NgbActiveModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-modal-erros",
  templateUrl: "./modal-erros.component.html",
  styleUrls: ["./modal-erros.component.css"]
})
export class ModalErrosComponent implements OnInit {

  closeResult = "";


  constructor(config: NgbModalConfig,private modalService: NgbModal, public activeModal: NgbActiveModal) {

    config.backdrop = "static";
    config.keyboard = false;

   }

  ngOnInit(): void {
  }

  @Input() confirmationBoxTitle;
  @Input() confirmationMessage;


}
