import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [

  {
    path: "/home",
    title: "PÁGINA INICIAL",
    icon: "mdi mdi-home",
    class: "",
    extralink: false,
    submenu: [],
    role: "none",
    redirect: false
  },
  {
    path: "/",
    title: "ACORDOS",
    icon: "mdi mdi-file",
    class: "has-arrow",
    extralink: false,
    role: "deal",
    redirect: false,
    submenu: [
      {
        path: "/deal/change-date",
        title: "MUDAR DATA",
        icon: "mdi mdi-calendar",
        class: "",
        extralink: false,
        submenu: [],
        role: "deal",
        redirect: true,
      },
      {
        path: "/deal/pre-fixed-page",
        title: "NOVO ACORDO",
        icon: "mdi mdi-file-check",
        class: "",
        extralink: false,
        submenu: [],
        role: "deal",
        redirect: true,
      }
    ]
  },
  {
    path: "/",
    title: "COMUNICAÇÃO",
    icon: "mdi mdi-email",
    class: "has-arrow",
    extralink: false,
    role: "schedule",
    redirect: false,
    submenu: [
      {
        path: "/schedule/import",
        title: "IMPORTAR CSV",
        icon: "mdi mdi-file-send",
        class: "",
        extralink: false,
        submenu: [],
        role: "schedule",
        redirect: false,
      }
    ]
  }
];
