import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { User } from "../../models";

import * as jwtDecode from "jwt-decode";


@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  validateToken(token) {
    const headers = new HttpHeaders({
      "Authorization": "bearer " + token,
      "Accept": "*/*",
    });

    return this.http.get<any>(`${environment.ATENDIMENTO_API}get-authorization-info`, {
      headers
    }).pipe(map((response) => {
      if (response.data.user.id) {
        let userLocal = {
          "id": 108792,
          "username": 'portal.cobranca',
          "email": "",
          "jwtToken": token,
          "zend": false
        };
        this.userSubject.next(userLocal);
        this.saveUser(userLocal);
      }
      this.router.navigate(["/home"]);

      return true;
    }));
  }


  login(username: string, password: string) {

    var fd = new FormData();
    fd.append('grant_type', 'password');
    fd.append('client_code', 'portal-cobranca');
    fd.append('username', username);
    fd.append('password', password);

    return this.http.post<any>(`${environment.KRAKEND}login`, fd)
      .pipe(
        map((response) => {
          return [response.access_token, username];
        })
      );
  }

  getToken(token) {

    const headers = new HttpHeaders({
      "Authorization": "Bearer " + token,
    });

    return this.http.post<any>(`${environment.KRAKEND}token`, '', { headers })
      .pipe(map((response) => {
        return response;
      }));
  }

  setUser(keyMakerToken, negotiorToken, username) {
    let dataUser = negotiorToken;
    let userLocal = {
      "id": dataUser.access_token.client_id,
      username,
      "jwtToken": keyMakerToken,
      "krakenId": keyMakerToken,
      "roles": dataUser.access_token.roles,
      "zend": true
    };
    this.userSubject.next(userLocal);
    this.saveUser(userLocal);
  }


  decodePayloadJWT(token): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  saveUser(user: User) {
    localStorage.setItem('User', JSON.stringify(user));
  }

  tokenExpired(expiry) {
    if (expiry === undefined || expiry === null) {
      return true;
    }
    return (Math.floor((new Date).getTime() / 1000)) >= expiry.exp;
  }

  hasRole(role) {
    let user = JSON. parse(localStorage.getItem('User'));
    if(user && user.roles) {
      if(user.roles.includes(role)){
        return true;
      }
      return false;
    }
    return false;
  }

  logout() {
    localStorage.removeItem('User');
    this.router.navigate(["/login"]);
  }


  // logout() {
  //     this.http.post<any>(`${environment.apiUrl}/users/revoke-token`, {}, { withCredentials: true }).subscribe();
  //     this.stopRefreshTokenTimer();
  //     this.userSubject.next(null);
  //     this.router.navigate(["/login"]);
  // }

  // refreshToken() {
  //     return this.http.post<any>(`${environment.apiUrl}/users/refresh-token`, {}, { withCredentials: true })
  //         .pipe(map((user) => {
  //             this.userSubject.next(user);
  //             this.startRefreshTokenTimer();
  //             return user;
  //         }));
  // }
  // helper methods

  // private refreshTokenTimeout;

  // private startRefreshTokenTimer() {
  //     // parse json object from base64 encoded jwt token
  //     const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split(".")[1]));

  //     // set a timeout to refresh the token a minute before it expires
  //     const expires = new Date(jwtToken.exp * 1000);
  //     const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //     this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  // }

  // private stopRefreshTokenTimer() {
  //     clearTimeout(this.refreshTokenTimeout);
  // }
}
