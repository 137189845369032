<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <li class="nav-item search-box">
        <!-- <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <div class="d-flex align-items-center">
                <i class="mdi mdi-magnify font-20 mr-1"></i>
                <div class="ml-1 d-none d-sm-block">
                    <span>Search</span>
                </div>
            </div>
        </a> -->
        <div class="titulo">
          <p>
            PLATAFORMA DE SERVIÇOS DE COBRANÇA
        </p>
        </div>
        <!-- <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form> -->
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <div class="user-icon">
       <div>

        <img class="img-user" src="assets/images/Icon-awesome-user-circle.svg" alt="user" class="rounded-circle" width="25">
        <span class="barra"> {{username}} </span>

        <img
          class="img-sair"
          src="assets/images/sair.svg"
          width="50"
          (click)="logout()"
        >

       </div>

      </div>

        <!-- <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/Icon-awesome-user-circle.svg" alt="user" class="rounded-circle" width="40">
            Cobrança
        </a> -->
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <div class="dropdown-divider"></div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
