import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./helpers/auth.guard";
import { ScheduleGuard } from "./helpers/schedule.guard";
import { ReceiveTokenComponent } from "./receive-token/receive-token.component";
import { Page404Component } from "./layouts/page404/page404.component";
import { LoginComponent } from "./login/login.component";


export const Approutes: Routes = [
	{
		path: "",
		component: FullComponent,
		children: [
      { path: "", redirectTo: "/home", pathMatch: "full" , canActivate: [AuthGuard]},

      {
				path: "home",
        loadChildren: ( ) => import("./home/home.module").then(m => m.HomeModule), // eslint-disable-line arrow-parens
        canActivate: [AuthGuard]
			},
			{
				path: "deal",
        loadChildren: ( ) => import("./dealComponent/component.module").then(m => m.ComponentsModule),  // eslint-disable-line arrow-parens
        canActivate: [AuthGuard]

      },
      {
        path: "schedule",
        loadChildren: () => import("./scheduleComponent/schedule.module").then(m => m.ScheduleModule),
        canActivate: [AuthGuard, ScheduleGuard]
      }
		]
  },
  {
     path: "token",
     component: ReceiveTokenComponent
  },
  {
    path: "notfound",
    component: Page404Component
 },
 {
  path: "login",
  component: LoginComponent
},
	{
		path: "**",
		redirectTo: "/home"
	}
];
