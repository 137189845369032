import { Component, OnInit, Input } from "@angular/core";
import {NgbModal, NgbActiveModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-modal-no-result",
  templateUrl: "./modal-no-result.component.html",
  styleUrls: ["./modal-no-result.component.css"]
})
export class ModalNoResultComponent implements OnInit {

  constructor(config: NgbModalConfig,private modalService: NgbModal, public activeModal: NgbActiveModal) {
    config.backdrop = "static";
    config.keyboard = false;

  }

  ngOnInit(): void {
  }
  @Input() confirmationBoxTitle;
  @Input() confirmationMessage;

}
